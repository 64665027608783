<template>
<div class="clearfix" :class="{ loading: loading }">
  <div id="image-manager" v-if="!loading" :animate="animate && !blocked">
    <div class="image-wrapper">
      <span id="bg-system"></span>
      <PreviewImage v-for="(item, index) in images" :key="index" :config="item.config" :images="item.images" imageDimension="350" :style="'image-elements-' + item.config"/>
      <template v-for="(item, index) in images" :key="index">
        <PreviewImage  v-if="item.config === 'base'"  :config="item.config" :images="item.images" imageDimension="250" :style="'image-elements-add-' + item.config"/>
      </template>
    </div>
  </div>
</div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { useStore } from 'vuex'
import PreviewImage from './PreviewImage'
export default {
  components: {
    PreviewImage
  },
  setup () {
    const store = useStore()
    const blocked = ref(true)
    const animate = ref(false)

    // computed
    const images = computed(() => {
      return store.getters['system/previewImages']
    })
    const loading = computed(() => {
      return store.getters['system/previewImagesLoading']
    })
    onMounted(() => {
      blocked.value = false
    })
    return {
      blocked,
      animate,
      loading,
      images
    }
  }
}
</script>
<style lang="scss" scoped>
#image-manager {
      position: relative;
      height: 273px;
      .blur-right {
        position: absolute;
        right: -37px;
        top: 0;
        z-index: 11;
        width: 120px;
        height: 100%;
        background: white;
        filter: blur(15px);
      }
      .blur-left {
        position: absolute;
        left: -23px;
        top: 0;
        z-index: 11;
        width: 100px;
        height: 100%;
        background: white;
        filter: blur(15px);
      }

      #bg-system {
        background: url('~@/assets/img/bg_system.jpg') no-repeat;
        width:100%;
        height: 100%;
        display: block;
      }

      .product-main-image {
        position: absolute;
        left: 365px;
        top: 30px;
        z-index: 0;
      }
    }
    .image-wrapper {
      width: 1140px;
      height: 100%;
      position: relative;
    }
    @media(max-width: 1200px) {
      #image-manager {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
    .image-wrapper {
      width: 825px;
    }
}
</style>
