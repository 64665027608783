<template>
  <table class="mb-3 table-steps">
    <tbody>
    <tr v-for="step in steps" :key="step.id">
      <td>{{ step.name }}:</td>
      <td>
        <i class="fas fa-pencil"></i>
        <a href="#" class="step-edit">
          {{ step.selectedLabel }}
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script setup>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'

const store = useStore()

const steps = computed(() => store.getters['system/steps'])
</script>
